



























import Vue from "vue";
import Component from "vue-class-component";
import IAppBar from "@/views/components/common/toolbar/IAppBar.vue";
import { refreshButtonVO } from "@/models/vos/ButtonVO";
import IToolbar from "@/views/components/common/toolbar/IToolbar.vue";
import { SchoolYear } from "@/models/SchoolYear";
import allSchoolYears from "@/graphql/schoolYears/allSchoolYears.graphql";
import PermissionsModule from "@/modules/PermissionsModule";
import { getModule } from "vuex-module-decorators";

@Component<SchoolYearsOverviewPage>({
  components: { IToolbar, IAppBar },
  apollo: {
    schoolYears: {
      query: allSchoolYears,
    },
  },
  metaInfo() {
    return {
      //@ts-ignore
      title: this.title,
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (
      to.name === this.$routes.ALL_YEARS &&
      this.permissionModule.currentSchoolYear
    ) {
      next({
        name: this.$routes.YEAR,
        params: {
          year_id: this.permissionModule.currentSchoolYear.id,
        },
      });
    } else {
      next();
    }
  },
})
export default class SchoolYearsOverviewPage extends Vue {
  protected schoolYearSearch: string = "";
  protected selectedYear: string = "";
  protected schoolYears: SchoolYear[] = [];

  protected permissionModule: PermissionsModule = getModule(PermissionsModule);

  protected get title(): string {
    return this.$t("locations.schoolYears").toString();
  }

  protected get buttons() {
    return [refreshButtonVO];
  }

  protected get filteredTabs() {
    return this.schoolYearSearch
      ? this.yearTabs.filter((tab) =>
          tab.title
            .toLocaleLowerCase()
            .includes(this.schoolYearSearch.toLocaleLowerCase())
        )
      : this.yearTabs;
  }

  protected get yearTabs() {
    return (
      this.schoolYears
        ? this.schoolYears.map((year) => {
            return {
              title: year.denomination ?? "",
              id: year.id,
              to: {
                name: this.$routes.YEAR,
                params: { year_id: year.id },
              },
              year,
            };
          })
        : []
    ).sort((a: { year: SchoolYear }, b: { year: SchoolYear }) => {
      try {
        if ((a.year.start ?? 0) < (b.year.start ?? 0)) {
          return 1;
        } else if ((a.year.start ?? 0) > (b.year.start ?? 0)) {
          return -1;
        } else {
          return 0;
        }
      } catch (e) {
        return 0;
      }
    });
  }
}
